<template>
  <a-layout class="login animated fadeIn">
    <div class="model">
      <div>
        <img style="height: 170px" src="@/assets/images/logo2.png" alt="">
        <div class="login-form">
          <h3>总后台登录</h3>
          <a-divider />
          <a-form :model="formModel" @finish="handleSubmitFinish" @finishFailed="handleSubmitFinishFailed">
            <a-form-item name="account" :rules="[{ required: true, message: '请输入用户名' }]">
              <a-input v-model:value="formModel.account" placeholder="用户名" class='input'>
                <template v-slot:prefix>
                  <user-outlined />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="password" :rules="[{ required: true, message: '请输入密码' }]">
              <a-input-password v-model:value="formModel.password" placeholder="密码" class='input'>
                <template v-slot:prefix>
                  <lock-outlined />
                </template>
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" htmlType="submit" class="login-form-button" :loading="loading">
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </a-layout>
</template>
<style lang="less">
@import "../style/views/login.less";
</style>
<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { sendLogin } from "../api/login";
import { $iscode } from "../utils/app";
import { inject, reactive, toRefs } from "vue";
export default {
  name: "Login",
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const state = reactive({
      loading: false,
      formModel: {
        account: "",
        password: "",
      },
    });
    const $router=inject("$router")
    const handleSubmitFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const handleSubmitFinish = async () => {
      state.loading = true;
      try {
        let res = await sendLogin({
          account: state.formModel.account,
          password: state.formModel.password,
        }).then(res=>res.data).catch(error=>error);
        state.loading = false;
        if ($iscode(res, true)) {
          if(!res.data.menu.length){
            $iscode({code:0,msg:"对不起该角色暂无任何权限"}, true)
            return
          }
          localStorage.setItem("token", res.data.apiAuth);
          if(res.data.menu[0].key == '/index'){
            $router.push("/")
          }else{
            if(res.data.menu[0].children[0].hidden==0){
              $router.push(res.data.menu[0].children[0].key)
            }
          }
          inject("$store").commit("user/SET_USER_TOKEN", res.data.apiAuth);
          inject("$store").dispatch('user/setUserInfo',res.data);
          
        }
      } catch (e) {
        state.loading = false;
      }
    };
    return {
      ...toRefs(state),
      handleSubmitFinishFailed,
      handleSubmitFinish,
    };
  },
};
</script>